<script>
	import { afterUpdate } from "svelte";
	import page from "page.js";
	import { GraphQLClient } from "graphql-request";
	import MainPage from "./MainPage.svelte";
	import Login from "./Login.svelte";
	import NotFound from "./NotFound.svelte";

	let apiurl = "https://aicon3t.nws.company:8543/api";
	if(localStorage.getItem("apiurl") != null){
		apiurl = localStorage.getItem("apiurl");
	}
	//const apiurl = "/api";
	let user = JSON.parse(localStorage.getItem("user"));
	let terminal = JSON.parse(localStorage.getItem("terminal"));
	let user_token = localStorage.getItem("user_token");
	let terminal_token = localStorage.getItem("terminal_token");
	let client = new GraphQLClient(apiurl);
	if (user_token !== null) {
		client.setHeaders({ Authorization: "Bearer " + user_token });
	}
	if (terminal_token !== null) {
		client.setHeaders({ Authorization: "Bearer " + terminal_token });
	}

	let current_page;
	let params = { client: client, user: user };

	page((ctx, next) => {
		user = JSON.parse(localStorage.getItem("user"));
		terminal = JSON.parse(localStorage.getItem("terminal"));
		params = { client: client, user: user };
		next();
	});
	page("/", () => (current_page = MainPage));
	page("/login", () => (current_page = Login));
	page("/*", () => (current_page = NotFound));
	page.start();

	afterUpdate(() => {
		if (user === null && terminal === null) page("/login");
	});

	//
	import { dictionary, locale } from "svelte-i18n";
	dictionary.set({
		ENGLISH: {
			"TIME TRACKING": "TIME TRACKING",
			"please wait": "please wait",
			Arrival: "Arrival",
			Departure: "Departure",
			"Work stoppages": "Work stoppages",
			"loading entry types": "loading entry types",
			"Work activities": "Work activities",
			Tags: "Tags",
			"loading tags": "loading tags",
			TAG: "TAG",
			"CURRENT ENTRY": "CURRENT ENTRY",
			STOP: "STOP",
			ARRIVAL: "ARRIVAL",
			TOTAL: "TOTAL",
			Error: "Error",
			"PERSONAL REPORT": "PERSONAL REPORT",
			"ACTIVITY TIMELINE": "ACTIVITY TIMELINE",
			"Hours tracked this month": "Total hours tracked",
			"Annual leave": "Annual leave",
			"days taken": "days taken",
			"days left": "days left",
			Disablement: "Other Absence",
			"Sick leave": "Sick leave",
			"Sick care": "Sick care",
			"I want to request": "I want to request",
			Request: "Request",
			"Current status": "Current status",
			"Set new status": "Set new status",
			"CHANGE STATUS": "CHANGE STATUS",
			"PERSONAL INFO": "PERSONAL INFO",
			Name: "Name",
			"Job Group": "Job Group",
			"LOG OUT": "LOG OUT",
			WARNING: "WARNING",
			"if you click on log out, you will need a new scan of the QR code for further login.":
				"if you click on log out, you will need a new scan of the QR code for further login.",
			"BOOKING TIME OFF": "REQUEST TIME OFF",
			"Approval Request is created": "Approval Request is created",
			Type: "Type",
			"Date from": "Date from",
			Time: "Time",
			"Date to": "Dátum to",
			"Add a private note": "Add a private note",
			"Your text": "Your text",
			"Notify me when the request is approved": "Notify me when the request is approved",
			"SEE WHOLE MONTH": "SEE WHOLE MONTH",
			"Whole Month": "Whole Month",
			"YOUR BOOKING REQUESTS": "YOUR BOOKING REQUESTS",
			"BOOK": "BOOK",
			Approved: "Approved",
			"Not approved": "Not approved",
			Unprocessed: "Unprocessed",
			Cancel: "Cancel",
			"Resend approval request notification for": "Resend approval request notification for",
			from: "from",
			"Can't resend approval request notification. Last resend was":
				"Can't resend approval request notification. Last resend was",
			at: "at",
			Resend: "Resend",
			APPROVAL: "APPROVAL",
			"Create Approval": "Create Approval",
			"day type": "day type",
			"entry type": "entry type",
			"Please enter your code:": "Please enter your code:",
		},
		SLOVAK: {
			"TIME TRACKING": "DOCHÁDZKA",
			"please wait": "počkajte prosím",
			Arrival: "Príchod",
			Departure: "Odchod",
			"Work stoppages": "Pracovné prestávky",
			"loading entry types": "načítavanie typov záznamov",
			"Work activities": "Pracovné činnosti",
			Tags: "Značky",
			"loading tags": "načítavanie značiek",
			TAG: "ZNAČKA",
			"CURRENT ENTRY": "AKTUÁLNY VSTUP",
			STOP: "STOP",
			ARRIVAL: "PRÍCHOD",
			TOTAL: "CELKOM",
			Error: "Chyba",
			"PERSONAL REPORT": "OSOBNÝ REPORT",
			"ACTIVITY TIMELINE": "HISTÓRIA",
			"Hours tracked this month": "Súčet hodín v mesiaci",
			"Annual leave": "Dovolenka za rok",
			"days taken": "vyčerpané dni",
			"days left": "zostávajúce dni",
			Disablement: "Práceneschopnosť",
			"Sick leave": "Návšteva lekára",
			"Sick care": "Lekár doprovod",
			"I want to request": "Rezervovať",
			Request: "VYTVORIŤ",
			"Current status": "Súčasný stav",
			"Set new status": "Zmeň stav",
			"CHANGE STATUS": "ZMEŇ STAV",
			"PERSONAL INFO": "OSOBNÉ ÚDAJE",
			Name: "Meno",
			"Job Group": "Pracovná skupina",
			"LOG OUT": "ODHLÁSIŤ",
			WARNING: "POZOR",
			"if you click on log out, you will need a new scan of the QR code for further login.":
				"ak kliknete na odhlásiť, budete potrebovať nové naskenovanie QR kódu na prihlásenie.",
			"BOOKING TIME OFF": "ŽIADANKA",
			"Approval Request is created": "Žiadanka je vytvorená",
			Type: "Typ",
			"Date from": "Dátum od",
			Time: "Čas",
			"Date to": "Dátum do",
			"Add a private note": "Pridajte súkromnú poznámku",
			"Your text": "Tvoj text",
			"Notify me when the request is approved": "Upozorniť ma, keď bude žiadanka schválená",
			"SEE WHOLE MONTH": "POZRITE SI CELÝ MESIAC",
			"Whole Month": "Celý mesiac",
			"YOUR BOOKING REQUESTS": "VAŠE ŽIADANKY",
			"BOOK": "ODOSLAŤ",
			Approved: "Schválené",
			"Not approved": "Neschválené",
			Unprocessed: "Nespracované",
			Cancel: "Zrušiť",
			"Resend approval request notification for": "Znova odoslať upozornenie na žiadanku o schválenie pre",
			from: "z",
			"Can't resend approval request notification. Last resend was":
				"Upozornenie na žiadanku o schválenie nie je možné znova odoslať. Posledné opätovné odoslanie bolo",
			at: "pri",
			Resend: "Pripomenúť",
			APPROVAL: "ŽIADANKY",
			"Create Approval": "Vytvoriť žiadanku",
			"day type": "celodenné",
			"entry type": "prerušenie",
			"Please enter your code:": "Zadajte svoj kód:",
		},
	});
	locale.set("ENGLISH");
</script>

<style>
	:global(body) {
		margin: 0;
		padding: 0;
	}
	main {
		text-align: center;
		margin: 0 auto;
	}
	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>

<main>
	<svelte:component this={current_page} {...params} />
</main>
