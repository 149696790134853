<script>
	import { createEventDispatcher } from "svelte";
    import { select_option } from "svelte/internal";

	export let length = 5;
	let code = "";
	let code2 = "";

	let list = Array.from(Array(Number(length)).keys());

	let value = '' 

	
	
	const dispatch = createEventDispatcher();

	function select(num) {
		return () => {
			code += num;
			if (code.length == length) {
				dispatch("submit", { code: code });
				code = "";
			}
		};
	}

	function select2(e) {
		value = e.key		
		console.log("value = "+e.key);
		if(value !== 'Enter'){
			if (value.length === 1 && value != 'b') {
				code2 += value;
			}			
		}else{
			console.log("code2 = "+code2);
			dispatch("submit", { code: code2 })
			setTimeout(() => {
				code2 = "";	
			}, 500);
			
			//die;
		}
	}


	function escapeRegExp(string) {
  		return string.replace(/[.0-9,a-z,A-Z]/g, "\\$&");
	}

			
	const backspace = () => (code = code.substring(0, code.length - 1));
	
</script>

<style>
	#main {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#numpad {
		display: grid;
		grid-template-columns: repeat(3, 14vh);
		grid-template-rows: repeat(4, 14vh);
		grid-gap: 0.5em;
		margin: 0 auto;
		border-top: 1px solid #ced6e0;
		padding-top: 2vh;
	}

	

	#code {
		display: flex;
		justify-content: center;
		margin: 10px;
	}
	.full-circle {
		border: 3px solid #2db7c3;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		height: 12px;
		width: 12px;
		margin-left: 1vh;
		margin-right: 1vh;
	}
	.empty-circle {
		border: 3px solid #ced6e0;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		height: 12px;
		width: 12px;
		margin-left: 1vh;
		margin-right: 1vh;
	}
	button {
		font-size: 3vh;
		background-color: #fff;
		border: 1px solid #ced6e0;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-webkit-border-radius: 50%;
		height: 10vh;
		width: 10vh;
		margin: auto;
	}

	.back {
		color: #00aeba;
		font-size: 40pt;
		border: 0px solid blue;
	}

	/* iPad */
	@media only screen and (device-width: 768px) {
		#numpad {
			border: 0px solid blue;
			grid-template-columns: repeat(3, 10vh);
		    grid-template-rows: repeat(4, 10vh);
		}
		button {
			font-size: 3vh;
			height: 8vh;
			width: 8vh;
		}
	}

	/* iPad Pro */
	@media only screen and (device-width: 1024px) {
		#numpad {
			border: 0px solid blue;
			grid-template-columns: repeat(3, 8vh);
		    grid-template-rows: repeat(4, 8vh);
		}
		button {
			font-size: 2vh;
			height: 6vh;
			width: 6vh;
		}
	}

	@media only screen and (device-width: 1366px) {
		#numpad {
			border: 0px solid blue;
			grid-template-columns: repeat(3, 8vh);
		    grid-template-rows: repeat(4, 8vh);
		}
		button {
			font-size: 2vh;
			height: 6vh;
			width: 6vh;
		}
	}

	/* otoc hack */
	@media only screen and (min-width: 568px) and (max-height: 411px) {
		#numpad {
			border: 0px solid blue;
			grid-template-columns: repeat(3, 10vh);
		    grid-template-rows: repeat(4, 10vh);
		}
		button {
			font-size: 3vh;
			height: 10vh;
			width: 10vh;
		}
		.back {
			margin-top: -5vh;
			font-size: 30pt;
		}
	}

	/* ios */
	@media (min-device-width:320px) and (max-device-width:768px) {
		#numpad {
			border: 0px solid blue;
			grid-template-columns: repeat(3, 10vh);
		    grid-template-rows: repeat(4, 10vh);
		}
    }
</style>


<div id="main">
	<div>
		<div id="code">
			{#each list as i}
				{#if code.length <= i}
					<div class="empty-circle" />
				{:else}
					<div class="full-circle" />
				{/if}
			{/each}
		</div>
		<div id="numpad">
			<button on:click={select(1)}>1</button>
			<button on:click={select(2)}>2</button>
			<button on:click={select(3)}>3</button>
			<button on:click={select(4)}>4</button>
			<button on:click={select(5)}>5</button>
			<button on:click={select(6)}>6</button>
			<button on:click={select(7)}>7</button>
			<button on:click={select(8)}>8</button>
			<button on:click={select(9)}>9</button>
			<div />
			<!-- Backspace -->
			<button on:click={select(0)}>0</button>
			<div class="back" on:click={backspace}>&#171;</div>			
		</div>
	</div>
</div>
<svelte:window on:keydown={select2} />