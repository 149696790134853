<script>
	import { afterUpdate, createEventDispatcher } from "svelte";
	import { Html5Qrcode } from "html5-qrcode";

	const dispatch = createEventDispatcher();

	let qrScanner = null;
	let error = "";

	afterUpdate(() => {
		if (error != "") return;
		if (qrScanner == null) qrScanner = new Html5Qrcode("reader");
		qrScanner
			.start(
				{ facingMode: { exact: "environment" } },
				{ fps: 10, qrbox: { width: window.screen.width * 0.7, height: window.screen.width * 0.7 } },
				(decodedText, decodedResult) => {
					console.log(`Code matched = ${decodedText}`, decodedResult);
					dispatch("qrcode", { code: decodedText });
				},
				(err) => console.warn(`Code scan error = ${err}`)
			)
			.catch((err) => {
				error = `Code scan error = ${err}`;
			});
	});

	function back() {
		dispatch("qrcode", { code: "" });
		qrScanner.stop();
	}
</script>

<style>
	#reader {
		width: 100%;
	}

	#message {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		height: 100vh;
		color: red;
		font-size: 40pt;
	}
	.btn-back {
		position: absolute;
		top: 0;
		left: 0;
		width: 10%;
		margin-top: 5px;
		margin-left: 5px;
	}
</style>

<div id="reader" />
{#if error != ''}
	<div id="message">{error}</div>
{/if}
<img
	on:click={back}
	src="/assets/back-button.svg"
	alt="back"
	class="btn-back"
	style="margin-top: {error == '' ? '5px' : '60px'}" />
