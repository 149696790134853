<script>
	import { afterUpdate } from "svelte";
	import { gql } from "graphql-request";
	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	import relativeTime from "dayjs/plugin/relativeTime";
	import { _ } from 'svelte-i18n';

	export let client;
	export let activePage = "history";

	let approvalsPromise = getApprovalRequest();
	let messagesPromise = getMessages(20);
	let messagesAllPromise;

	let currentDate = null;
	let selectedAR = null;

	async function getMessages(limit, month, year) {
		let resp = await client.request(
			gql`
				query($limit: Int, $m: Int, $y: Int) {
					messages(limit: $limit, month: $m, year: $y) {
						id
						created
						message
					}
				}
			`,
			{
				limit: limit,
				m: month,
				y: year,
			}
		);

		return resp.messages;
	}

	async function getApprovalRequest() {
		let resp = await client.request(
			gql`
				{
					approvalRequests {
						approvalRequests {
							id
							start
							end
							approved
							dayType {
								name
							}
							entryType {
								name
							}
							canResend
							resendTime
						}
					}
				}
			`
		);

		return resp.approvalRequests.approvalRequests;
	}

	function popup(ar) {
		selectedAR = ar;
	}

	async function resendApprovalRequest(id) {
		let resp = await client.request(
			gql`
				mutation($apid: ID!) {
					resendApprovalRequest(id: $apid)
				}
			`,
			{ apid: id }
		);
		approvalsPromise = getApprovalRequest();
		selectedAR = null;
	}
</script>

<style>
	.grid {
		border-top: 1px solid #d6edef;
		display: grid;
		grid-template-columns: auto;
	}

	.border {
		grid-column-start: 1;
		grid-column-end: 4;
		border-top: 1px solid #d6edef;
		margin-bottom: -10pt;
	}

	.date {
		grid-column-start: 1;
		grid-column-end: 2;
		text-align: left;
		line-height: 15pt;
		font-weight: 700;
	}

	.msg {
		grid-column-start: 2;
		grid-column-end: 4;
		text-align: left;
		line-height: 15pt;
	}

	.see {
		text-align: left;
		color: #8bd7de;
		font-weight: 700;
		border-top: 1px solid #d6edef;
		margin-top: 7pt;
		padding-top: 7pt;
	}

	.app-date {
		grid-column-start: 1;
		grid-column-end: 4;
		text-align: left;
		margin-top: 5pt;
		padding-top: 5pt;
	}

	.app-name {
		grid-column-start: 1;
		grid-column-end: 3;
		text-align: left;
		font-weight: 500;
	}

	.app {
		color: green;
		font-weight: 600;
	}

	.no-app {
		color: red;
		font-weight: 600;
	}

	.un-proc {
		color: #8bd7de;
		font-weight: 600;
	}

	.resend {
		font-size: 30pt;
		grid-column-start: 4;
		grid-column-end: 5;
		text-align: center;
	}

	/* iPad hack */
	@media all and (device-width: 1280px) and (device-height: 800px) and (orientation: landscape) {
		.ipad {
			border: 0px solid blue;
			margin-bottom: 60pt;
		}
	}

	@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
		.ipad {
			border: 0px solid blue;
			margin-bottom: 50pt;
		}
	}

	@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
		.ipad {
			border: 0px solid blue;
			margin-bottom: 80pt;
		}
	}

	/* hack ked je horizontalne start */
	@media all and (orientation: landscape) {
		.container {
			border: 0px solid red;
			width: 70%;
			margin: 0 auto;
		}
	}
	/* hack ked je horizontalne end */

	.popup {
		position: fixed;
		top: 20%;
		left: 20%;
		width: 40vw;
		height: 50vh;
		background-color: #fff;
		border: 1px solid #d6edef;
		padding: 10vw;
	}

	.popup > :nth-child(1) {
		height: 30vh;
	}
	.popup > :nth-child(2) {
		height: 10vh;
	}
</style>

<div class="container">
	{#if activePage == 'history'}
		{#await messagesPromise}
			<p>{$_("please wait")}...</p>
		{:then msgs}
			<div class="grid">
				<h3>{$_("ACTIVITY TIMELINE")}</h3>
			</div>
			<div class="grid">
				{#each msgs as msg, i}
					{#if i == 0 || dayjs(msgs[i - 1].created).format('YYYY-MM-DD') != dayjs(msgs[i].created).format('YYYY-MM-DD')}
						<div class="border">&nbsp;</div>
						<div class="date">{dayjs(msgs[i].created).format('DD MMM YYYY')}</div>
					{/if}

					<div class="msg">
						{dayjs(msgs[i].created).format('HH:mm')} &nbsp;
						{@html msgs[i].message}
					</div>
				{/each}
			</div>

			<div
				class="grid see"
				on:click={() => {
					messagesAllPromise = getMessages(null, dayjs().month() + 1, dayjs().year());
					activePage = 'wholeMonth';
				}}>
				{$_("SEE WHOLE MONTH")}&gt;
			</div>
		{/await}

		{#await approvalsPromise}
			<p>&nbsp;</p>
		{:then approvalRequests}
			<div class="grid">
				<h3>{$_("YOUR BOOKING REQUESTS")}</h3>
			</div>
			<div class="grid ipad">
				{#each approvalRequests as ar}
					<div class="app-date">
						<strong>{dayjs(ar.start).format('DD MMM YYYY')}</strong>
						{dayjs(ar.start).format('| HH:mm')} - <strong>{dayjs(ar.end).format('DD MMM YYYY')}</strong>
						{dayjs(ar.end).format('| HH:mm')}
					</div>
					<div class="app-name">
						{ar.dayType !== null ? ar.dayType.name : ar.entryType !== null ? ar.entryType.name : ''}
					</div>
					<div>
						{#if ar.approved === true}
							<span class="app">{$_("Approved")}</span>
						{:else if ar.approved === false}
							<span class="no-app">{$_("Not approved")}</span>
						{:else}<span class="un-proc">{$_("Unprocessed")}</span>{/if}
					</div>
					{#if ar.approved == null}<span class="resend" on:click={() => popup(ar)}>&#x2709;</span>{/if}
				{/each}
			</div>
			<div class="grid">&nbsp;</div>
		{/await}
		<!--history end-->
	{:else if activePage == 'wholeMonth'}
		<!-- whole month start-->

		{#await messagesAllPromise}
			<p>{$_("please wait")}...</p>
		{:then msgsAll}
			<h3>{$_("Whole Month")}</h3>
			<div class="grid ipad">
				{#each msgsAll as msg, i}
					{#if i == 0 || dayjs(msgsAll[i - 1].created).format('YYYY-MM-DD') != dayjs(msgsAll[i].created).format('YYYY-MM-DD')}
						<div class="border">&nbsp;</div>
						<div class="date">{dayjs(msgsAll[i].created).format('DD MMM YYYY')}</div>
					{/if}

					<div class="msg">
						{dayjs(msgsAll[i].created).format('HH:mm')} &nbsp;
						{@html msgsAll[i].message}
					</div>
				{/each}
			</div>
		{/await}
		<!-- whole month end-->
	{/if}
	{#if selectedAR != null}
		<div class="popup">
			<div>
				{#if selectedAR.canResend}
				{$_("Resend approval request notification for")} {selectedAR.dayType !== null ? selectedAR.dayType.name : selectedAR.entryType !== null ? selectedAR.entryType.name : ''}
				{$_("from")} {dayjs(selectedAR.start).format('DD MMM YYYY')} to {dayjs(selectedAR.end).format('DD MMM YYYY')}?
				{:else}
				{$_("Can't resend approval request notification. Last resend was")} {dayjs(selectedAR.resendTime).format('DD MMM YYYY')}
				{$_("at")} {dayjs(selectedAR.resendTime).format('HH:mm')}.
				{/if}
			</div>
			<div>
				{#if selectedAR.canResend}<button on:click={resendApprovalRequest(selectedAR.id)}>{$_("Resend")}</button>{/if}
				<button
					on:click={() => {
						selectedAR = null;
					}}>{$_("Cancel")}</button>
			</div>
		</div>
	{/if}
</div>
