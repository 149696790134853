<style>
	#content {
		width: calc(min(85vw, 85vh));
		margin: auto;
	}
	#content img {
		height: 5vh;
	}
	form {
		display: flex;
		flex-direction: column;
	}
	form > fieldset > input {
		border: none;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 10px 0 10px 10px;
		outline-width: 0;
	}
	fieldset {
		margin: 0 0 10px 0;
		padding: 0;
		border: 1px solid #dbe1e7;
		border-radius: 5px;
		width: 100%;
		height: 6vh;
	}
	input[type="submit"] {
		height: 6vh;
		border: none;
		border-radius: 5px;
		color: #ffffff;
		text-transform: uppercase;
		font-weight: bold;
		background-color: #ced6e0;
	}
	h2 {
		color: #313c4a;
		font-size: 3vw;
	}
</style>

	<div id="content">
		<img src="/assets/AICON logo.svg" alt="aicon logo" />
		<h2>Page not found</h2>
	</div>
