<script>
	import { afterUpdate } from "svelte";
	import page from "page.js";
	import { gql } from "graphql-request";
	import { locale } from "svelte-i18n";
	import hashes from "jshashes";
	import QrScanner from "./QrScanner.svelte";

	export let user;
	export let terminal;
	export let client;

	let username = "";
	let password = "";
	let key = "";
	let secret = "";
	let error = "";
	let qrScanner;

	let mode = "terminal";

	afterUpdate(() => {
		if (localStorage.getItem("user") !== null || localStorage.getItem("terminal") !== null) {
			page("/");
			return;
		}
		if (!navigator.onLine) {
			page("/dashboard");
		}
	});

	async function login_user() {
		try {
			const data = await client.request(
				gql`
					mutation Login($username: String!, $password: String!) {
						login(username: $username, password: $password) {
							token
							user {
								id
								username
								group
								email
								firstname
								lastname
								takePhoto
								takeGps
								language
								company {
									name
								}
							}
						}
					}
				`,
				{ username: username, password: password }
			);
			user = data.login.user;
			if (user.group === "SUPERADMIN" || user.group === "ADMIN") {
				error = "Only user or manager can login to AICON3 Terminal";
				return;
			}
			localStorage.setItem("user", JSON.stringify(user));
			localStorage.setItem("user_token", data.login.token);
			client.setHeaders({ Authorization: "Bearer " + data.login.token });
			locale.set(user.language);
			page("/");
		} catch (err) {
			console.log(err);
			error = err.response.errors[0].message;
		}
	}

	async function login_terminal() {
		try {
			let resp = await client.request(
				gql`
					mutation {
						terminalChallenge
					}
				`
			);
			let challenge = resp.terminalChallenge;
			let sha256 = new hashes.SHA256();
			let signature = sha256.hex_hmac(secret, challenge + key);
			let authorization = {
				key: key,
				challenge: challenge,
				signature: signature,
			};
			resp = await client.request(
				gql`
					mutation TerminalLogin($authorization: String!) {
						terminalLogin(authorization: $authorization) {
							token
							terminal {
								id
								name
								company {
									name
								}
							}
						}
					}
				`,
				{ authorization: btoa(JSON.stringify(authorization)) }
			);
			terminal = resp.terminalLogin.terminal;
			localStorage.setItem("terminal", JSON.stringify(terminal));
			localStorage.setItem("terminal_token", resp.terminalLogin.token);
			client.setHeaders({
				Authorization: "Bearer " + resp.terminalLogin.token,
			});
			page("/");
		} catch (err) {
			console.log(err);
			error = err.response.errors[0].message;
		}
	}

	async function login_qrcode(event) {
		if (event.detail.code == "") {
			page("/");
			return;
		}
		try {
			const data = await client.request(
				gql`
					mutation TokenFromQRCode($qrcode: String!) {
						getTokenFromQRCode(qrcode: $qrcode)
					}
				`,
				{ qrcode: event.detail.code }
			);
			let token = data.getTokenFromQRCode;
			let token_structures = token.split(".");
			if (token_structures.length !== 3) {
				throw "not valit token";
			}

			let claims = JSON.parse(atob(token_structures[1]));
			if (claims.hasOwnProperty("group")) {
				user = claims;
				if (user.group === "SUPERADMIN" || user.group === "ADMIN") {
					throw "Only user or manager can login to AICON3 Terminal";
				}
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("user_token", token);
				client.setHeaders({
					Authorization: "Bearer " + token,
				});
				page("/");
			} else {
				terminal = claims;
				localStorage.setItem("terminal", JSON.stringify(terminal));
				localStorage.setItem("terminal_token", token);
				client.setHeaders({
					Authorization: "Bearer " + token,
				});
				page("/");
			}
			qrScanner.stop();
		} catch (err) {
			mode = "terminal";
			console.log(err);
			error = err;
		}
	}
</script>

<style>
	#content {
		width: calc(min(85vw, 85vh));
		margin: auto;
	}
	#content img {
		height: 5vh;
	}
	form {
		display: flex;
		flex-direction: column;
	}
	#qrcodebutton {
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		color: #ffffff;
		background-color: #2db7c3;
		font-weight: bold;
		width: 100%;
		height: 6vh;
		border-radius: 5px;
	}
	#mode_buttons {
		display: flex;
		justify-content: center;
		height: 12vh;
	}
	.mode_terminal {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
		width: 50%;
		color: #a4b0be;
		background-color: #ffffff;
		border-top: 1px solid #dbe1e7;
		border-left: 1px solid #dbe1e7;
		border-bottom: 1px solid #dbe1e7;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	.mode_terminal img {
		height: 40%;
		margin-bottom: 5%;
	}
	.mode_user {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
		width: 50%;
		color: #a4b0be;
		background-color: #ffffff;
		border: 1px solid #dbe1e7;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.mode_user img {
		height: 40%;
		margin-bottom: 5%;
	}
	.pressed {
		color: #313c4a;
		background-color: #f8f8f8;
	}
	form > fieldset > input {
		border: none;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 10px 0 10px 10px;
		outline-width: 0;
	}
	fieldset {
		margin: 0 0 10px 0;
		padding: 0;
		border: 1px solid #dbe1e7;
		border-radius: 5px;
		width: 100%;
		height: 6vh;
	}
	input[type="submit"] {
		height: 6vh;
		border: none;
		border-radius: 5px;
		color: #ffffff;
		text-transform: uppercase;
		font-weight: bold;
		background-color: #ced6e0;
	}
	h2 {
		color: #313c4a;
		font-size: 3vw;
	}
</style>

{#if mode == 'qrScanner'}
	<QrScanner bind:this={qrScanner} on:qrcode={login_qrcode} />
{:else}
	<div id="content">
		<img src="/assets/AICON logo.svg" alt="aicon logo" />
		<h2>Please, choose your mode:</h2>
		<div id="mode_buttons">
			<div
				class={mode == 'terminal' ? 'mode_terminal pressed' : 'mode_terminal'}
				on:click={() => {
					mode = 'terminal';
				}}>
				<img src="/assets/terminal_mode.svg" alt="terminal mode" /> Terminal mode
			</div>
			<div
				class={mode == 'user' ? 'mode_user pressed' : 'mode_user'}
				on:click={() => {
					mode = 'user';
				}}>
				<img src="/assets/user_mode.svg" alt="user mode" /> Personal mode
			</div>
		</div>
		<h3>Use QR code to log in:</h3>
		<div
			id="qrcodebutton"
			on:click={() => {
				mode = 'qrScanner';
			}}>
			scan qr code
		</div>
		<h3>or enter your credentials:</h3>
		{#if mode == 'user'}
			<form on:submit|preventDefault={login_user}>
				<p>{error}</p>
				<fieldset>
					<legend align="left">username/email</legend>
					<input type="text" name="username" bind:value={username} />
				</fieldset>
				<fieldset>
					<legend align="left">password</legend>
					<input type="password" name="password" bind:value={password} />
				</fieldset>
				<input type="submit" value="login" />
			</form>
		{:else}
			<form on:submit|preventDefault={login_terminal}>
				<p>{error}</p>
				<fieldset>
					<legend align="left">key</legend>
					<input id="key" type="text" name="key" bind:value={key} />
				</fieldset>
				<fieldset>
					<legend align="left">secret</legend>
					<input id="secret" type="text" name="secret" bind:value={secret} />
				</fieldset>
				<input type="submit" value="login" />
			</form>
		{/if}
	</div>
{/if}
