<script>
	import { afterUpdate } from "svelte";
	import { locale } from "svelte-i18n";
	import Dashboard from "./Dashboard.svelte";
	import Numpad from "./Numpad.svelte";
	import Actions from "./Actions.svelte";
	import History from "./History.svelte";
	import page from "page.js";
	import QrCode from "svelte-qrcode";
	import { gql } from "graphql-request";
	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	import relativeTime from "dayjs/plugin/relativeTime";
	dayjs.extend(duration);
	dayjs.extend(relativeTime);

	export let client;
	export let user;
	export let terminal = undefined;
	export let logoutTime = undefined;
	export let logoutTimeShort = undefined;

	let errorMessage = "";
	let activePage = "time";
	let dashboardActivePage;
	let qrCodeValue;
	let showQrCode = false;
	
	afterUpdate(() => {
		if (localStorage.getItem("user") === null && localStorage.getItem("terminal") === null) {
			page("/login");
			return;
		}
		if (user !== null) {
			locale.set(user.language);
		}
	});

	function logout() {
		localStorage.removeItem("user");
		localStorage.removeItem("user_token");
		activePage = "time";
		user = null;
		showQrCode = false;
		if (terminal === null) {
			page("/login");
		}
	}
	
	/* TIMER things 60000*/
	
	//const timerLogoutTime = logoutTime;
	let timerInterval = 50;
	let timerValue = 0;
	//let timerValueMax = timerLogoutTime;
	let progressBarWidth = 0;

	setInterval(() => {
		if (terminal === null) {
			return;
		}
		timerValue -= timerInterval;
		progressBarWidth = (timerValue / logoutTime) * 100;
		if (timerValue == 0) {
			logout();
		}
	}, timerInterval);

	/*
	if (terminal === null) {
		setInterval(updateUserObject, 60000);
	}
   */

	function setTimer(time) {
		if (terminal === null) {
			return;
		}
		timerValue = time;
		logoutTime = time;
	}
	
	async function updateUserObject() {
		if (user === null) {
			return;
		}
		try {
			const data = await client.request(
				gql`
					{
						dashboard {
							me {
								id
								username
								group
								email
								firstname
								lastname
								takePhoto
								takeGps
								language
								company {
									name
									longTerminalTimeout
									shortTerminalTimeout
								}
							}
						}
					}
				`
			);
			user = data.dashboard.me;
			logoutTime = data.dashboard.me.company.longTerminalTimeout*1000;
			logoutTimeShort = data.dashboard.me.company.shortTerminalTimeout*1000;
			localStorage.setItem("logoutTime", JSON.stringify(logoutTime));
			localStorage.setItem("logoutTimeShort", JSON.stringify(logoutTimeShort));
			localStorage.setItem("user", JSON.stringify(user));
			console.log("user == "+user);
			locale.set(user.language);
		} catch (err) {
			console.log("error === "+err);
			err = err.response.errors[0].message;
			return;
		}
	}
	
	async function loginSubmit(event) {
		let token = localStorage.getItem("terminal_token");
		client.setHeaders({
			Authorization: "Bearer " + token,
			"X-Code": event.detail.code,
		});
		try {
			let res = await client.request(
				gql`
					{
						dashboard {
							me {
								username
								language
								takePhoto
								takeGps
								company {
									id
									longTerminalTimeout
									shortTerminalTimeout
								}
							}
						}
					}
				`
			);
			user = res.dashboard.me;
			logoutTime = res.dashboard.me.company.longTerminalTimeout*1000;
			logoutTimeShort = res.dashboard.me.company.shortTerminalTimeout*1000;
			console.log("logoutTimeShort == "+logoutTimeShort);
			locale.set(user.language);
			//start logout timer loop if we're in terminal mode
			setTimer(logoutTime);
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				if (err.response.error == '{"errors":[{"message":"terminal deactivated"}]}') {
					logout();
					localStorage.removeItem("terminal");
					localStorage.removeItem("terminal_token");
					user = null;
					client.setHeaders({});
					page("/login");
					return;
				}
				if (err.response.error.includes("code not found")) {
					errorMessage = "invalid code";
					setTimeout(() => {
						errorMessage = "";
					}, 4000);
				}
			}
			console.log(err);
		}
	}


	
</script>

<style>
	.header {
		display: flex;
		border-bottom: 1px solid #f2f4f7;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.header img {
		height: 4vw;
	}
	.header div {
		color: #636f7c;
		font-size: 4vw;
		margin-left: auto;
		text-transform: uppercase;
	}
	#main {
		min-height: 100%;
		margin-left: 5vw;
		margin-right: 5vw;
	}
	#footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f8f8f8;
		box-shadow: 4px -3px 5px #0000001a;
		width: 100%;
		height: 10vw;
		position: fixed;
		bottom: 0;
		left: 0;
	}
	#footer img {
		height: 5vw;
	}
	#footer :nth-child(2) {
		margin-left: 12vw;
	}
	#footer :last-child {
		margin-right: 12vw;
	}

	.empty {
		height: 40pt;
	}

	#qrCodeClick {
		width: 30pt;
		position: absolute;
		top: 0;
		right: 0;
	}

	#viewQr {
		margin-top: 50pt;
	}

	#logoutProgressBar {
		background-color: #0000ff;
		height: 1px;
		position: absolute;
		top: 0;
		left: 0;
		transition: width 100ms;
	}

	/* hack ked je horizontalne start */
	@media all and (orientation: landscape) {
		.header img {
			height: 2vw;
		}
		.header div {
			font-size: 2vw;
		}
		#footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #f8f8f8;
			box-shadow: 4px -3px 5px #0000001a;
			width: 100%;
			height: 5vw;
			position: fixed;
			bottom: 0;
			left: 0;
		}
		#footer img {
			height: 3vw;
		}
	}
	/* hack ked je horizontalne end */
</style>

<div id="main" on:click={() => setTimer(logoutTime)}>
	{#if user !== null}
		<div class="header">
			<img src="/assets/AICON logo.svg" alt="aicon logo" />
			<div>{user.username}</div>
		</div>
		{#if activePage == 'time'}
			<Actions
				{client}
				{user}
				{terminal}
				on:action={() => {
					setTimer(logoutTimeShort);
					if (terminal === null) {
						updateUserObject();
					}
				}} />
		{:else if activePage == 'dashboard'}
			<Dashboard
				on:action={() => setTimer(logoutTime)}
				bind:activePage={dashboardActivePage}
				{user}
				{client}
				{terminal} />
		{:else if activePage == 'history'}
			<History bind:activePage={dashboardActivePage} {user} {client} />
		{/if}

		<div class="empty">&nbsp;</div>

		<div id="footer">
			<div id="logoutProgressBar" style="width: {progressBarWidth}%">&nbsp;</div>
			<img
				src="/assets/Profile{activePage == 'dashboard' ? '-active' : ''}.svg"
				on:click={() => {
					setTimer(logoutTime);
					activePage = 'dashboard';
					dashboardActivePage = 'dashboard';
				}}
				alt="dashboard" />
			<img
				src="/assets/Time{activePage == 'time' ? '-active' : ''}.svg"
				on:click={() => {
					setTimer(logoutTime);
					activePage = 'time';
				}}
				alt="time" />
			<img
				src="/assets/History{activePage == 'history' ? '-active' : ''}.svg"
				on:click={() => {
					setTimer(logoutTime);
					activePage = 'history';
					dashboardActivePage = 'history';
				}}
				alt="history" />
		</div>
	{:else if terminal !== null}
		<img src="/assets/AICON logo.svg" alt="aicon logo" />
		
		<img
			src="/assets/qr.png"
			alt="qr code"
			id="qrCodeClick"
			on:click={() => {
				if (showQrCode) {
					showQrCode = false;
					return;
				}
				qrCodeValue = terminal.id + new Date()
						.toISOString()
						.substr(0, 10) + Math.random().toString(16).substr(2, 8);
				showQrCode = true;
				setTimer(30000);
				window.setTimeout(logout, 15000); //after 15s qrcode hide
			}} />
		{#if showQrCode}
			<div id="viewQr">
				<QrCode value={qrCodeValue} size="300" />
			</div>
		{:else}
			<h3 style={errorMessage ? 'color: #ff0000' : ''}>
				{errorMessage ? errorMessage : 'Please enter your code:'}
			</h3>
			<Numpad length="5" on:submit={loginSubmit} />
		{/if}
	{/if}
</div>
