<script>
	import { gql } from "graphql-request";
	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	import relativeTime from "dayjs/plugin/relativeTime";
	import { createEventDispatcher } from "svelte";
	import QrScanner from "./QrScanner.svelte";
	import { _ } from "svelte-i18n";

	const dispatch = createEventDispatcher();

	export let client;
	export let user;
	export let terminal;

	let dayPromise;
	let workPromise;
	let entryTypesPaidPromise;
	let entryTypesUnpaidPromise;
	let entryTypesPaidGroup = null;
	let entryTypesUnpaidGroup = null;
	let tagsPromise;

	let mode = "actions";
	let qrScanner;
	let selectedEntryTypeID;
	let errorMessage = "";
	let video;
	let canvas;

	function emitAction() {
		dispatch("action", {});
	}

	function get() {
		dayPromise = getCurrentDay();
		entryTypesPaidPromise = getEntryTypes("PAID");
		entryTypesUnpaidPromise = getEntryTypes("UNPAID");
		tagsPromise = getTags();
		workPromise = getDayTypeWork();
	}

	get();

	async function getCurrentDay() {
		if (user === null) {
			return new Promise(() => null);
		}
		let res = await client.request(gql`
			{
				currentDay {
					id
					type {
						name
					}
					start
					end
					entries {
						type {
							name
							color
						}
						start
						end
					}
					tags {
						start
						end
						tag {
							name
							color
						}
					}
				}
			}
		`);
		return res.currentDay;
	}

	async function getDayTypeWork() {
		if (user === null) {
			return new Promise(() => null);
		}
		let res = await client.request(gql`
			{
				dayTypes(id: "00000000-0000-0000-0000-000000000000") {
					id
					mustScanPlace
				}
			}
		`);
		return res.dayTypes[0];
	}

	async function arrival_or_departure(currentDay) {
		if (currentDay === null) {
			let work = await workPromise;
			if (terminal === null && work.mustScanPlace) {
				selectedEntryTypeID = "";
				mode = "qrScanner";
				return;
			}
			arrival();
		} else {
			departure();
		}
	}

	async function getPhoto() {
		if (!user.takePhoto) return null;
		let stream = null;

		try {
			stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false, facingMode: "user" });
		} catch (error) {
			alert(error.message);
			return;
		}

		video.srcObject = stream;
		await new Promise((resolve) => setTimeout(resolve, 500));
		canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
		let image_data_url = canvas.toDataURL("image/jpeg").split(";base64,")[1];
		video.srcObject = null;
		stream.getTracks()[0].stop;
		return image_data_url;
	}

	async function getGPS() {
		if (!user.takeGps) return null;
		if (navigator.geolocation) {
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}).then((pos) => {
				return { lat: pos.coords.latitude, long: pos.coords.longitude };
			});
		} else {
			console.log("Geolocation is not supported by this browser.");
		}
		return null;
	}

	async function arrival(qrCode) {
		let photo = await getPhoto();
		let gps = await getGPS();
		client
			.request(
				gql`
					mutation($qrCode: String, $photo: String, $gps: GPSInput) {
						startDay(qrCode: $qrCode, photo: $photo, gps: $gps) {
							id
							type {
								name
								type
							}
							start
							end
							entries {
								type {
									name
									color
								}
								start
								end
							}
							tags {
								start
								end
								tag {
									name
									color
								}
							}
						}
					}
				`,
				{
					qrCode: qrCode ? qrCode : null,
					photo: photo,
					gps: gps,
				}
			)
			.then((resp) => {
				dayPromise = resp.startDay;
				emitAction();
			})
			.catch((err) => {
				errorMessage = err.response.errors[0].message;
			});
	}

	async function departure() {
		let photo = await getPhoto();
		let gps = await getGPS();
		let res = await client.request(
			gql`
				mutation($photo: String, $gps: GPSInput) {
					endDay(photo: $photo, gps: $gps) {
						id
					}
				}
			`,
			{
				photo: photo,
				gps: gps,
			}
		);
		dayPromise = getCurrentDay();
		emitAction();
	}

	async function getEntryTypes(type) {
		if (user === null) {
			return null;
		}
		let res = await client.request(
			gql`
				query EntryTypes($type: Type) {
					entryTypes(orderby: POPULARITY, type: $type) {
						id
						name
						color
						mustScanPlace
						group
					}
				}
			`,
			{ type: type }
		);
		let entries = [];
		for (const e of res.entryTypes) {
			if (e.group == null) {
				entries.push({
					group: e.name,
					entries: [e],
				});
				continue;
			}
			let index = -1;
			for (let i = 0; i < entries.length; i++) {
				if (entries[i].group == e.group) {
					index = i;
				}
			}
			if (index == -1) {
				entries.push({
					group: e.group,
					entries: [e],
				});
				continue;
			} else {
				entries[index].entries.push(e);
			}
		}
		return entries;
	}

	async function startEntry(entryTypeID, qrCode) {
		let photo = await getPhoto();
		let gps = await getGPS();
		client
			.request(
				gql`
					mutation StartEntry($entryTypeID: ID, $qrCode: String, $photo: String, $gps: GPSInput) {
						startEntry(entryTypeID: $entryTypeID, qrCode: $qrCode, photo: $photo, gps: $gps) {
							id
						}
					}
				`,
				{
					entryTypeID: entryTypeID,
					qrCode: qrCode,
					photo: photo,
					gps: gps,
				}
			)
			.then((resp) => {
				dayPromise = getCurrentDay();
				emitAction();
			})
			.catch((err) => {
				errorMessage = err.response.errors[0].message;
			});
	}

	async function stopEntry(entryTypeID) {
		let photo = await getPhoto();
		let gps = await getGPS();
		client.request(
			gql`
				mutation($photo: String, $gps: GPSInput) {
					stopEntry(photo: $photo, gps: $gps) {
						id
					}
				}
			`,
			{
				photo: photo,
				gps: gps,
			}
		);
		dayPromise = getCurrentDay();
		emitAction();
	}

	function ongoingEntry(currentDay) {
		if (currentDay === null) {
			return null;
		}
		for (const entry of currentDay.entries) {
			if (entry.end === null) {
				return entry;
			}
		}
		return null;
	}

	function ongoingTag(currentDay) {
		if (currentDay === null) {
			return null;
		}
		for (const tag of currentDay.tags) {
			if (tag.end === null) {
				return tag;
			}
		}
		return null;
	}

	async function getTags(type) {
		if (user === null) {
			return new Promise(() => null);
		}
		let res = await client.request(
			gql`
				query TagsTypes {
					tags {
						id
						name
						color
					}
				}
			`
		);
		return res.tags;
	}

	async function startTag(tagID) {
		client.request(
			gql`
				mutation StartTagInstance($tagID: ID) {
					startTagInstance(tagID: $tagID) {
						id
					}
				}
			`,
			{ tagID: tagID }
		);
		dayPromise = getCurrentDay();
	}

	async function stopTag(entryTypeID) {
		client.request(
			gql`
				mutation {
					stopTagInstance {
						id
					}
				}
			`
		);
		dayPromise = getCurrentDay();
	}

	function place_qrcode(event) {
		mode = "actions";
		if (event.detail.code == "") {
			return;
		}
		if (selectedEntryTypeID !== "") {
			startEntry(selectedEntryTypeID, event.detail.code);
		} else {
			arrival(event.detail.code);
		}
		qrScanner.stop();
	}
</script>

<style>
	/*@media all and (orientation:portrait) { 
		.container_active{
		border: 1px solid blue;
	   }
	}*/

	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #000000;
		background-color: #ffffff;
		border-radius: 5px;
		border: 2px solid #82dbac;
		height: 10vw;
		font-size: 4vw;
	}
	.button[disabled="true"] {
		background-color: #f1f2f6;
	}

	/* hack ked je horizontalne start */
	@media all and (orientation: landscape) {
		.container_active {
			border: 0px solid red;
			width: 70%;
			margin: 0 auto;
		}
		.button {
			height: 6vw;
			font-size: 2vw;
		}
	}
	/* hack ked je horizontalne end */

	.horizontalScroller {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	.horizontalScroller::-webkit-scrollbar {
		display: none;
	}
	.horizontalScroller .button {
		flex: 0 0 auto;
		margin-left: 10px;
		margin-right: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.horizontalScroller :first-child {
		margin-left: 0;
	}
	.horizontalScroller :last-child {
		margin-right: 0;
	}
	h2 {
		font-size: 15px;
		margin-bottom: 4vh;
	}
	h4 {
		font-size: 13px;
		color: #636f7c;
		margin-bottom: 1vh;
	}
	#entry_box {
		display: flex;
		justify-content: space-between;
	}
	#entry_name {
		text-align: left;
		font-size: 3vh;
	}
	#entry_time {
		text-align: left;
		font-size: 2vh;
		color: #ff8d50;
	}
	#entry_stop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #ff8d50;
		border-radius: 5px;
		height: 10vw;
		font-size: 4vw;
		color: white;
		padding: 0 20px 0 20px;
		font-weight: bold;
	}
	#entry_stop img {
		height: 4vw;
		margin-right: 10px;
	}
	#current_day_stats {
		display: grid;
		grid-template-columns: auto auto auto auto;
		background: #f1f2f6;
		line-height: 40pt;
		margin-top: 5pt;
	}
	#current_day_stats .label {
		color: #636f7c;
		font-size: 10pt;
	}
	#current_day_stats .data {
		border: 0px solid blue;
		margin-left: -9pt;
		text-align: left;
		font-size: 14pt;
	}
	/* iPad hack */
	@media all and (device-width: 1280px) and (device-height: 800px) and (orientation: landscape) {
		#current_day_stats {
			border: 0px solid blue;
			margin-bottom: 60pt;
		}
	}

	@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
		#current_day_stats {
			border: 0px solid blue;
			margin-bottom: 50pt;
		}
	}

	@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
		#current_day_stats {
			border: 0px solid blue;
			margin-bottom: 80pt;
		}
	}

	.qRblock {
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		z-index: 1000;
		position: absolute;
	}

	video {
		display: none;
	}

	canvas {
		display: none;
	}
</style>

<video bind:this={video} width="320" height="240" autoplay><track kind="captions" /></video>
<canvas bind:this={canvas} width="320" height="240" />

{#if mode == 'qrScanner'}
	<div class="qRblock">
		<QrScanner bind:this={qrScanner} on:qrcode={place_qrcode} />
	</div>
{:else if mode == 'actions'}
	<div class="container_active">
		{#await dayPromise}
			<p>{$_('please wait')} ...</p>
		{:then currentDay}
			<h2>{$_('TIME TRACKING')}</h2>

			<h4>{$_('Arrival')}/{$_('Departure')}</h4>
			<div class="button" on:click={arrival_or_departure(currentDay)}>
				{#if currentDay === null}{$_('Arrival')}{:else}{$_('Departure')}{/if}
			</div>
			<h4>{$_('Work stoppages')}</h4>
			<div class="horizontalScroller">
				{#await entryTypesUnpaidPromise}
					<p>{$_('loading entry types')}...</p>
				{:then entryTypes}
					{#each entryTypes as et}
						<div
							class="button"
							disabled={currentDay === null || (entryTypesUnpaidGroup != null && entryTypesUnpaidGroup.group != et.group)}
							on:click={() => {
								emitAction();
								if (currentDay === null) {
									return;
								}
								if (et.entries.length == 1) {
									let e = et.entries[0];
									if (terminal === null && e.mustScanPlace) {
										selectedEntryTypeID = e.id;
										mode = 'qrScanner';
										return;
									}
									startEntry(e.id, null);
									return;
								}
								if (entryTypesUnpaidGroup != null && entryTypesUnpaidGroup.group == et.group) {
									entryTypesUnpaidGroup = null;
									return;
								}
								entryTypesUnpaidGroup = et;
							}}
							style="border-color: {et.entries[0].color}">
							{et.group}
						</div>
					{/each}
				{/await}
			</div>
			{#if entryTypesUnpaidGroup !== null}
				<div class="horizontalScroller" style="margin-top: 10pt">
					{#each entryTypesUnpaidGroup.entries as et}
						<div
							class="button"
							disabled={currentDay === null}
							on:click={() => {
								emitAction();
								if (currentDay === null) {
									return;
								}
								if (terminal === null && et.mustScanPlace) {
									selectedEntryTypeID = et.id;
									mode = 'qrScanner';
									return;
								}
								startEntry(et.id, null);
							}}
							style="border-color: {et.color}">
							{et.name}
						</div>
					{/each}
				</div>
			{/if}
			<h4>{$_('Work activities')}</h4>
			<div class="horizontalScroller">
				{#await entryTypesPaidPromise}
					<p>{$_('loading entry types')}...</p>
				{:then entryTypes}
					{#each entryTypes as et}
						<div
							class="button"
							disabled={currentDay === null || (entryTypesPaidGroup != null && entryTypesPaidGroup.group != et.group)}
							on:click={() => {
								emitAction();
								if (currentDay === null) {
									return;
								}
								if (et.entries.length == 1) {
									let e = et.entries[0];
									if (terminal === null && e.mustScanPlace) {
										selectedEntryTypeID = e.id;
										mode = 'qrScanner';
										return;
									}
									startEntry(e.id, null);
									return;
								}
								if (entryTypesPaidGroup != null && entryTypesPaidGroup.group == et.group) {
									entryTypesPaidGroup = null;
									return;
								}
								entryTypesPaidGroup = et;
							}}
							style="border-color: {et.color}">
							{et.group}
						</div>
					{/each}
				{/await}
			</div>
			{#if entryTypesPaidGroup !== null}
				<div class="horizontalScroller" style="margin-top: 10pt">
					{#each entryTypesPaidGroup.entries as et}
						<div
							class="button"
							disabled={currentDay === null}
							on:click={() => {
								emitAction();
								if (currentDay === null) {
									return;
								}
								if (terminal === null && et.mustScanPlace) {
									selectedEntryTypeID = et.id;
									mode = 'qrScanner';
									return;
								}
								startEntry(et.id, null);
							}}
							style="border-color: {et.color}">
							{et.name}
						</div>
					{/each}
				</div>
			{/if}

			<h4>{$_('Tags')}</h4>
			<div class="horizontalScroller">
				{#await tagsPromise}
					<p>{$_('loading tags')}...</p>
				{:then tags}
					{#each tags as tag}
						<div
							class="button"
							disabled={currentDay === null}
							on:click={() => {
								emitAction();
								if (currentDay !== null) startTag(tag.id);
							}}
							style="border-color: {tag.color}">
							{tag.name}
						</div>
					{/each}
				{/await}
			</div>
			<h2>{$_('CURRENT ENTRY')}/{$_('TAG')}</h2>
			{#if ongoingEntry(currentDay)}
				<div id="entry_box">
					<div>
						<div id="entry_name">{ongoingEntry(currentDay).type.name}</div>
						<div id="entry_time">
							{dayjs.duration(dayjs().diff(dayjs(ongoingEntry(currentDay).start))).humanize()}
						</div>
					</div>
					<div id="entry_stop" on:click={stopEntry}>
						<img src="/assets/stopwatch.svg" alt="stopwatch" />{$_('STOP')}
					</div>
				</div>
			{/if}
			{#if ongoingTag(currentDay)}
				<div id="entry_box" style="margin-top: 5pt;">
					<div>
						<div id="entry_name">{ongoingTag(currentDay).tag.name}</div>
						<div id="entry_time">
							{dayjs.duration(dayjs().diff(dayjs(ongoingTag(currentDay).start))).humanize()}
						</div>
					</div>
					<div id="entry_stop" on:click={stopTag}>
						<img src="/assets/stopwatch.svg" alt="stopwatch" />STOP
					</div>
				</div>
			{/if}
			{#if currentDay !== null}
				<div id="current_day_stats">
					<div class="label">{$_('ARRIVAL')}:</div>
					<div class="data">{dayjs(currentDay.start).format('HH:mm')}</div>
					<div class="label">{$_('TOTAL')}:</div>
					<div class="data">{dayjs.duration(dayjs().diff(dayjs(currentDay.start))).format('HH:mm')}</div>
				</div>
				<div class="grid">&nbsp;</div>
			{/if}
			{#if errorMessage !== ''}
				<div>{$_('Error')}: {errorMessage}</div>
			{/if}
		{/await}
	</div>
{/if}
