<script>
	import { afterUpdate } from "svelte";
	import { gql } from "graphql-request";
	import dayjs from "dayjs";
	import page from "page.js";
	import duration from "dayjs/plugin/duration";
	import relativeTime from "dayjs/plugin/relativeTime";
	import { createEventDispatcher } from "svelte";
	import { _ } from "svelte-i18n";

	const dispatch = createEventDispatcher();

	dayjs.extend(duration);
	dayjs.extend(relativeTime);

	export let user;
	export let client;
	export let activePage = "dashboard";
	export let terminal;

	let approvalRequestType;
	let statusDayType;
	let start;
	let dateFrom = "";
	let timeFrom;
	let end;
	let dateTo = "";
	let timeTo;
	let note;
	let notify;
	let msg = "";

	let dashboardPromise = getDashboard();
	let typesPromise = getTypes();

	function emitAction() {
		dispatch("action", {});
	}

	async function getDashboard() {
		try {
			const data = await client.request(
				gql`
					{
						dashboard {
							me {
								firstname
								lastname
								email
								jobGroup {
									name
								}
							}
							status
							thisMonthHours
							lastMonthHours
							holidayRights
							holidaySpend
							disablementRights
							disablementSpend
							sickRights
							sickSpend
							careRights
							careSpend
						}
					}
				`
			);
			return data.dashboard;
		} catch (err) {
			console.log(err);
			error = err.response.errors[0].message;
			return null;
		}
	}

	async function getTypes(type) {
		let types = await client.request(
			gql`
				{
					dayTypes(statusType: true) {
						id
						name
					}
					entryTypes(statusType: true) {
						id
						name
					}
				}
			`
		);
		for (let i = 0; i < types.dayTypes.length; i++) {
			types.dayTypes[i].id = "d" + types.dayTypes[i].id;
		}
		for (let i = 0; i < types.entryTypes.length; i++) {
			types.entryTypes[i].id = "e" + types.entryTypes[i].id;
		}
		let allTypes = types.dayTypes.concat(types.entryTypes);
		approvalRequestType = allTypes[0].id;
		statusDayType = allTypes[0].id;
		return allTypes;
	}

	async function createApprovalRequest() {
		if (dateTo == "" || dateFrom == "" || msg == 1) {
			return;
		}
		try {
			if (timeFrom == "") {
				timeFrom = "09:00:00";
			}
			if (timeTo == "") {
				timeTo = "17:00:00";
			}
			let approvalRequestDayType = null;
			let approvalRequestEntryType = null;
			if (approvalRequestType.startsWith("d")) {
				approvalRequestDayType = approvalRequestType.substr(1);
			}
			if (approvalRequestType.startsWith("e")) {
				approvalRequestEntryType = approvalRequestType.substr(1);
			}
			await client.request(
				gql`
					mutation($approvalRequest: ApprovalRequestInput!) {
						createApprovalRequest(approvalRequest: $approvalRequest) {
							id
						}
					}
				`,
				{
					approvalRequest: {
						dayTypeID: approvalRequestDayType,
						entryTypeID: approvalRequestEntryType,
						startDate: dateFrom,
						startTime: timeFrom,
						endDate: dateTo,
						endTime: timeTo,
						notify: notify == 1,
						note: note,
					},
				}
			);
			msg = 1;
			setTimeout(() => {
				msg = "";
				activePage = "dashboard";
			}, 3000);
		} catch (e) {
			console.log(e);
		}
	}

	async function changeStatus() {
		try {
			await client.request(
				gql`
					mutation($dayTypeID: ID!) {
						startStatus(dayTypeID: $dayTypeID) {
							id
						}
					}
				`,
				{
					dayTypeID: statusDayType.substr(1),
				}
			);
			dashboardPromise = getDashboard();
		} catch (e) {
			console.log(e);
		}
	}

	function logout() {
		localStorage.removeItem("user");
		localStorage.removeItem("user_token");
		user = null;
		if (terminal === null) {
			page("/login");
		}
	}

	function formatSeconds(seconds) {
		return (
			Math.round(seconds / 3600)
				.toString()
				.padStart(2, "0") +
			":" +
			Math.round((seconds % 3600) / 60)
				.toString()
				.padStart(2, "0")
		);
	}
</script>

<style>
	span {
		color: #a4b0be;
	}
	.grid-container {
		display: grid;
		grid-template-columns: auto auto auto;
	}
	.hours-txt {
		text-align: left;
		font-size: 10pt;
		font-weight: bold;
		background: #d6edef;
		padding: 10pt 0 10pt 5pt;
		border: 0px solid red;
	}
	.hours-txt small {
		font-size: 9pt;
		color: #636f7c;
	}
	.hours-num {
		grid-column-start: 2;
		grid-column-end: 4;
		font-size: 20pt;
		font-weight: bold;
		background: #d6edef;
		padding: 8pt 5pt 2pt 0;
		border: 0px solid blue;
	}

	.annual-left {
		margin-top: 7pt;
		font-size: 10pt;
		font-weight: bold;
		text-align: left;
		padding-top: 4pt;
		border-bottom: 1px solid #d6edef;
	}
	.annual-right {
		margin-top: 7pt;
		font-size: 20pt;
		text-align: right;
		padding-bottom: 5pt;
		border-bottom: 1px solid #d6edef;
	}
	.ann-small {
		color: #313c4a;
		font-size: 9pt;
		display: block;
		margin-top: -1pt;
		border: 0px solid red;
	}

	.grid-buttons {
		display: flex;
	}
	.book {
		flex: 1;
		margin-top: 10pt;
		border: 0px solid red;
	}

	.field {
		flex: 1;
		border: 0px solid rgb(239, 214, 216);
		margin-top: 10pt;
		margin-right: 5pt;
	}
	fieldset {
		border: 1px solid #d6edef;
		border-radius: 5px;
	}
	fieldset select {
		border: 0px solid blue;
		margin-left: -15pt;
		margin-bottom: -10pt;
		margin-top: -10pt;
		background: #fff;
	}
	fieldset select option {
		color: #000;
	}
	.book-button {
		border: 1px solid #2db7c3;
		border-radius: 5px;
		background: #2db7c3;
		color: #ffffff;
		margin-top: 7pt;
		line-height: 36pt;
	}
	.book-button span {
		color: #ffffff;
	}

	.personal {
		border-top: 1px solid #d6edef;
		margin-bottom: 15pt;
	}
	.inf-left {
		grid-column-start: 1;
		grid-column-end: 2;
		margin-top: 10pt;
		text-align: left;
		color: #000;
		font-weight: bold;
		font-size: 10pt;
	}
	.inf-right {
		grid-column-start: 2;
		grid-column-end: 4;
		margin-top: 10pt;
		text-align: left;
		color: #000;
		font-weight: bold;
		font-size: 10pt;
	}

	.empty {
		height: 1pt;
	}

	.current_status {
		grid-column-start: 1;
		grid-column-end: 3;
		margin-top: 10pt;
		margin-bottom: -15pt;
		text-align: left;
		color: #000;
		font-weight: bold;
		font-size: 10pt;
		border: 0px solid blue;
	}

	/*.current_status span {
		color: #20bf6b;
	}*/

	.status_right {
		color: #20bf6b;
	}

	.log-out {
		color: #ff4d4d;
		border-top: 1px solid #ff4d4d;
		margin-bottom: 15pt;
	}

	.log-out-nadpis {
		color: #ff4d4d;
		margin-bottom: 5pt;
		border: 0px solid blue;
	}

	.log-out-txt {
		grid-column-start: 1;
		grid-column-end: 4;
		color: #ff4d4d;
		text-align: left;
		margin-top: 7pt;
	}

	.log-out-txt span {
		font-style: italic;
		color: #ff4d4d;
	}

	.log-out-button {
		color: #fff;
		background-color: #ff4d4d;
		text-align: center;
		border-radius: 5px;
		margin-top: 7pt;
		line-height: 36pt;
		grid-column-start: 1;
		grid-column-end: 4;
	}

	.nadpis {
		margin-bottom: 5pt;
		border: 0px solid blue;
	}

	/* create approval request start */
	.offline-grid {
		display: grid;
		grid-template-columns: auto;
		border: 0px solid blue;
	}

	.field-book {
		text-align: left;
		grid-column-start: 1;
		grid-column-end: 2;
		flex: 1;
		border: 0px solid rgb(239, 214, 216);
		margin-top: 10pt;
		margin-right: 5pt;
	}

	.field-book select {
		width: 98%;
		margin-left: -5pt;
		background-color: #ffffff;
	}

	.input-date {
		width: 98%;
		border: 0px solid rgb(239, 214, 216);
		margin-bottom: -5pt;
		background-color: #ffffff;
	}

	.field-book textarea {
		width: 98%;
		border: 0;
	}

	.btn-book {
		border: 1px solid #2db7c3;
		border-radius: 5px;
		background: #2db7c3;
		color: #ffffff;
		margin-top: 7pt;
		margin-bottom: 20pt;
		line-height: 30pt;
		clear: both;
	}
	.btn-book span {
		color: #ffffff;
	}

	.btn-book-disabled {
		border: 1px solid #2db7c3;
		border-radius: 5px;
		margin-top: 7pt;
		margin-bottom: 20pt;
		line-height: 30pt;
	}

	#msg {
		color: #2db7c3;
		background-color: #fff;
		border: 1px solid #2db7c3;
	}
	/* create approval request end */

	/* iPad hack */
	@media all and (device-width: 1280px) and (device-height: 800px) and (orientation: landscape) {
		.log-out {
			border: 0px solid blue;
			margin-bottom: 80pt;
		}
	}

	@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
		.log-out {
			border: 0px solid blue;
			margin-bottom: 50pt;
		}
	}

	@media all and (device-width: 1366px) and (device-height: 1024px) and (orientation: landscape) {
		.log-out {
			border: 0px solid blue;
			margin-bottom: 80pt;
		}
	}

	/* hack ked je horizontalne start */
	@media all and (orientation: landscape) {
		.container {
			border: 0px solid red;
			width: 70%;
			margin: 0 auto;
		}
	}
</style>

<!-- check online/offline-->
<div class="container">
	{#if activePage == 'dashboard'}
		{#await dashboardPromise}
			<p>{$_('please wait')} ...</p>
		{:then dashboard}
			<h3>{$_('PERSONAL REPORT')}</h3>
			<div class="grid-container">
				<div class="hours-txt">
					{$_('Hours tracked this month')}<br />
					<small>({dayjs().subtract(1, 'month').format('MMMM YYYY')} | {formatSeconds(dashboard.lastMonthHours)}h)</small>
				</div>
				<div class="hours-num">{formatSeconds(dashboard.thisMonthHours)}h</div>
				<div class="annual-left">{$_('Annual leave')}</div>
				<div class="annual-right">
					{dashboard.holidaySpend.toFixed(1)}
					<br /><small class="ann-small">{$_('days taken')}</small>
				</div>
				<div class="annual-right">
					{(dashboard.holidayRights - dashboard.holidaySpend).toFixed(1)}
					<br /><small class="ann-small">{$_('days left')}</small>
				</div>

				<div class="annual-left">{$_('Disablement')}</div>
				<div class="annual-right">
					{dashboard.disablementSpend.toFixed(1)}<br /><small class="ann-small">{$_('days taken')}</small>
				</div>
				<div class="annual-right">
					{(dashboard.disablementRights - dashboard.disablementSpend).toFixed(1)}
					<br /><small class="ann-small">{$_('days left')}</small>
				</div>

				<div class="annual-left">{$_('Sick leave')}</div>
				<div class="annual-right">
					{dashboard.sickSpend.toFixed(1)}<br /><small class="ann-small">{$_('days taken')}</small>
				</div>
				<div class="annual-right">
					{(dashboard.sickRights - dashboard.sickSpend).toFixed(1)}
					<br /><small class="ann-small">{$_('days left')}</small>
				</div>

				<div class="annual-left">{$_('Sick care')}</div>
				<div class="annual-right">
					{dashboard.careSpend.toFixed(1)}<br /><small class="ann-small">{$_('days taken')}</small>
				</div>
				<div class="annual-right">
					{(dashboard.careRights - dashboard.careSpend).toFixed(1)}
					<br /><small class="ann-small">{$_('days left')}</small>
				</div>
				<!-- /3 row-->
			</div>
			<!--/grid-container-->

			<h3 class="nadpis">{$_('APPROVAL')}</h3>
			<div class="grid-buttons personal">
				<div class="field">
					<fieldset>
						<legend>{$_('Create Approval')}</legend>
						<select bind:value={approvalRequestType}>
							{#await typesPromise then types}
								{#each types as type}
									<option value={type.id} selected={approvalRequestType === type.id}>
										{#if type.id.startsWith('d')}{$_('day type')} - {type.name}{/if}
										{#if type.id.startsWith('e')}{$_('entry type')} - {type.name}{/if}
									</option>
								{/each}
							{/await}
						</select>
					</fieldset>
				</div>

				<div class="book">
					<p
						class="book-button"
						on:click={() => {
							emitAction();
							activePage = 'approvalcreate';
						}}>
						{$_('Request')}
					</p>
				</div>
			</div>
			<!--/grid-buttons-->

			<!-- status start 
			<h3 class="nadpis">{$_('Current Status')}</h3>
			<div class="grid-container personal">
				<div class="current_status">{$_('Current status')}: <span>{dashboard.status}</span></div>
			</div>
			<div class="grid-buttons">
				<div class="field">
					<fieldset>
						<legend>{$_('Set new status')}</legend>
						<select bind:value={statusDayType}>
							{#await typesPromise then types}
								{#each types as type}
									{#if type.id.startsWith('d')}
										<option value={type.id} selected={statusDayType === type.id}>
											{type.name}
										</option>
									{/if}
								{/each}
							{/await}
						</select>
					</fieldset>
				</div>

				<div class="book">
					<p class="book-button" on:click={changeStatus}>{$_('CHANGE STATUS')}</p>
				</div>
			</div>
			<!--/grid-buttons-->
			<!-- status end -->

			<!-- personal start-->
			<h3 class="nadpis">{$_('PERSONAL INFO')}</h3>
			<div class="grid-container personal">
				<div class="inf-left">{$_('Name')}:</div>
				<div class="inf-right">{dashboard.me.firstname}&nbsp;{dashboard.me.lastname}</div>
				<div class="inf-left">{$_('Current status')}:</div>
				<div class="inf-right status_right">{dashboard.status}</div>
				<div class="inf-left">{$_('Job Group')}:</div>
				<div class="inf-right">
					{#if dashboard.me.jobGroup !== null}{dashboard.me.jobGroup.name}{/if}
				</div>
			</div>
			<!--/grid-container-->
			<div class="empty">&nbsp;</div>
			<!-- personal end-->

			{#if terminal == null}
				<!-- log out start-->
				<h3 class="log-out-nadpis">{$_('LOG OUT')}</h3>
				<div class="grid-container log-out">
					<div class="log-out-txt">
						{$_('WARNING')}: <span>
							{$_('if you click on log out, you will need a new scan of the QR code for further login.')}
						</span>
					</div>
					<p class="log-out-button" on:click={logout}>{$_('LOG OUT')}</p>
				</div>
				<!-- log out end-->
			{/if}
		{/await}
	{:else if activePage == 'approvalcreate'}
		<div class="offline-grid">
			<h3>{$_('BOOKING TIME OFF')}</h3>
		</div>

		{#if msg == 1}
			<div id="msg">
				<h4>{$_('Approval Request is created')}.</h4>
			</div>
		{/if}

		<div class="offline-grid">
			<div class="field-book">
				<fieldset>
					<legend>{$_('Type')}</legend>
					<select bind:value={approvalRequestType}>
						{#await typesPromise then types}
							{#each types as type}
								<option value={type.id} selected={approvalRequestType === type.id}>{type.name}</option>
							{/each}
						{/await}
					</select>
				</fieldset>
			</div>

			<!-- start start-->
			<div class="grid-buttons">
				<div class="field">
					<fieldset>
						<legend>{$_('Date from')}</legend>
						<input type="date" on:input={emitAction()} bind:value={dateFrom} class="input-date" />
					</fieldset>
				</div>

				<div class="field">
					<fieldset>
						<legend>{$_('Time')}</legend>
						<input type="time" on:input={emitAction()} bind:value={timeFrom} class="input-date" />
					</fieldset>
				</div>
			</div>
			<!-- start end-->

			<!-- start start-->
			<div class="grid-buttons">
				<div class="field">
					<fieldset>
						<legend>{$_('Date to')}</legend>
						<input type="date" on:input={emitAction()} bind:value={dateTo} class="input-date" />
					</fieldset>
				</div>

				<div class="field">
					<fieldset>
						<legend>{$_('Time')}</legend>
						<input type="time" on:input={emitAction()} bind:value={timeTo} class="input-date" />
					</fieldset>
				</div>
			</div>
			<!-- start end-->

			<div class="field-book">
				<fieldset>
					<legend>{$_('Add a private note')}</legend>
					<textarea placeholder={$_('Your text')} bind:value={note} />
				</fieldset>
			</div>
			
			<div class="field-book">
				<input type="checkbox" on:input={emitAction()} bind:value={notify} checked />
				<span>{$_('Notify me when the request is approved')}</span>
			</div>

			<div
				class:btn-book={dateTo != '' && dateFrom != ''}
				class:btn-book-disabled={dateTo == '' || dateFrom == '' || msg == 1}>
				<span on:click={createApprovalRequest}>{$_('BOOK')}</span>
			</div>
		</div>
	{/if}
</div>
